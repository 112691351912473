import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { HookFormFileUploadField } from '@shared/ui/HookFormFileUploadField';
import { Button } from '@shared/ui/buttons/Button';
import { useNavigate } from '@hooks/useNavigate';

import { TextareaField } from '@shared/ui/TextareaField';
import { TextField } from '@shared/ui/TextField';
import { ErrorList } from '@shared/ui/ErrorList';

import { Routes } from '@shared/enums';
import { useCreateLetterMutation } from '@store/api/santa-claus.api';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useActiveTab } from '@widgets/cabinet/customer/hooks/useActiveTab';
import { CheckboxField } from '@shared/ui/CheckboxField';
import { useCanCreateApplication } from '@widgets/cabinet/customer/hooks/useCanCreateApplication';

interface FormValues {
  name: string;
  age: number;
  text: string;
  birthCertSerial: string;
  isChildWithDisabilities: boolean;
  isChildFromLargeFamily: boolean;
  isChildOrphan: boolean;
  birthCertNumber: string;
  picture: string | null;
}

const fields = {
  name: 'name',
  age: 'age',
  text: 'text',
  birthCertSerial: 'birthCertSerial',
  birthCertNumber: 'birthCertNumber',
  picture: 'picture',
  isChildWithDisabilities: 'isChildWithDisabilities',
  isChildFromLargeFamily: 'isChildFromLargeFamily',
  isChildOrphan: 'isChildOrphan',
};

const defaultValues = {
  [fields.name]: '',
  [fields.age]: null,
  [fields.birthCertSerial]: '',
  [fields.birthCertNumber]: '',
  [fields.text]: '',
  [fields.picture]: null,
  [fields.isChildWithDisabilities]: false,
  [fields.isChildFromLargeFamily]: false,
  [fields.isChildOrphan]: false,
};

const validationSchema = yup.object().shape({
  [fields.name]: yup.string().trim().required('errors.required'),
  [fields.age]: yup
    .number()
    .typeError('errors.invalid-value')
    .integer('errors.invalid-value')
    .required('errors.required')
    .moreThan(-1, 'errors.invalid-value')
    .lessThan(15, 'errors.invalid-value'),
  [fields.text]: yup.string().trim().required('errors.required').max(5000, 'errors.too-long'),
  [fields.birthCertSerial]: yup.string().trim().required('errors.required'),
  [fields.birthCertNumber]: yup
    .string()
    .trim()
    .required('errors.required')
    .matches(/^\d{6}$/, { message: 'Номер cвідоцтва повинен складатися з 6 цифр' }),
  [fields.picture]: yup.mixed().required('errors.required'),
});

export const SantaClausLetterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createLetter] = useCreateLetterMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [, setActiveTab] = useActiveTab();

  const [canCreate] = useCanCreateApplication();

  const [fileLabel, setFileLabel] = useState('');

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    watch,
    getValues,
    handleSubmit,
    formState: { isSubmitting, isValidating },
  } = methods;

  useEffect(() => {
    setFileLabel(getValues().picture ? 'Різдвяний малюнок додано' : 'Додати Різдвяний малюнок');
  }, [watch('picture')]);

  const onFormSubmit = handleSubmit(async (values) => {
    try {
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('age', String(values.age));
      formData.append('text', values.text);
      formData.append('birthCertNumber', values.birthCertNumber);
      formData.append('birthCertSerial', values.birthCertSerial);
      formData.append('isChildWithDisabilities', String(values.isChildWithDisabilities));
      formData.append('isChildOrphan', String(values.isChildOrphan));
      formData.append('isChildFromLargeFamily', String(values.isChildFromLargeFamily));

      if (values.picture && values.picture.length > 0) {
        const picture = values.picture[0] as unknown as File;

        formData.append('picture', picture, picture.name);
      }

      await createLetter(formData).unwrap();

      await navigate(Routes.CustomerCabinet);

      setActiveTab('new');
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit} className="max-w-[648px] mx-auto">
        <div className="sign-up-form-card">
          <div className="flex flex-col space-y-10 md:space-y-0 md:flex-row md:items-start space-x-0 md:space-x-4">
            <TextField id={fields.name} type="text" label="child-name" className="grow" />

            <TextField id={fields.age} type="number" label="child-age" className="md:w-[128px]" />
          </div>

          <div className="flex flex-col space-y-10 md:space-y-0 md:flex-row md:items-start space-x-0 md:space-x-4 mt-10">
            <TextField id={fields.birthCertNumber} type="text" label="№ Свідоцтва про народження" className="grow" />

            <TextField id={fields.birthCertSerial} type="text" label="Серія" className="md:w-[128px]" />
          </div>

          <TextareaField id={fields.text} className="mt-10" label="Напишіть листа..." placeholder="Напишіть листа..." />

          <p className="mt-5">Якщо ви підпадаєте під одну з цих категорій відмітьте її:</p>

          <CheckboxField id={fields.isChildWithDisabilities} label="Дитина з інвалідністю" className="mt-5" />

          <CheckboxField id={fields.isChildFromLargeFamily} label="Багатодітна родина" className="mt-10" />

          <CheckboxField id={fields.isChildOrphan} label="Дитина сирота" className="mt-10" />

          <HookFormFileUploadField id={fields.picture} label={fileLabel} className="mt-10" />

          <ErrorList errors={apiErrors || []} className="mt-2" />

          {/* <div className="flex justify-center mt-6">
            <Button as="button" variant="primary" type="submit" disabled={isSubmitting || isValidating || !canCreate}>
              {t('send')}
            </Button>
          </div> */}
        </div>
      </form>
    </FormProvider>
  );
};
