import { IconCheckboxForm } from '@shared/icons/IconCheckboxForm';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CheckboxFieldProps {
  id: string;
  name?: string;
  label: string | ReactNode;
  className?: string;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { id, name = id, label, className = '' } = props;

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const { t } = useTranslation();

  const isChecked = watch(name);
  const errorText = errors[name]?.message;

  return (
    <div className={clsx('relative', className)}>
      <input {...register(name)} id={id} type="checkbox" className="hidden" />
      <IconCheckboxForm checked={isChecked} className="absolute left-0 top-0 block pointer-events-none" />
      <label htmlFor={id} className="block py-1 pl-10 text-xxs cursor-pointer">
        {label}
      </label>
      {errorText && <span className="absolute left-0 top-full mt-[5px] text-xxs text-carnation">{t(errorText)}</span>}
    </div>
  );
};
