import React from 'react';
import { useTranslation } from 'react-i18next';

export const SantaClausTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="santa">
      <div className="santa__letter">
        <div className="flex flex-col items-center sm:justify-center mt-[180px] sm:mt-[220px]">
          <h1 className="mb-4 text-xl text-center text-xl sm:text-2xl">{t('santa-letter')}</h1>

          <div className="max-w-[240px] text-xs sm:max-w-[380px] sm:text-sm">
            <p className="mb-5">{t('santa-letter-desc')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
