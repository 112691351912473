import { useDispatch, useSelector } from 'react-redux';

import { customerActions } from '@store/slices/customer/slice';
import { selectActiveTab } from '@store/slices/customer/selectors';

export function useActiveTab() {
  const dispatch = useDispatch();

  return [useSelector(selectActiveTab), (tab: string) => dispatch(customerActions.changeActiveTab(tab))] as const;
}
