import React from 'react';

import { SantaClausLetterForm } from '@widgets/santa-claus/letter-form';
import { SantaClausTitle } from '@widgets/santa-claus/title';

import config from '@config';
import { Routes } from '@shared/enums';

import { useNavigate } from '@hooks/useNavigate';
import { CabinetCustomerCanCreateApplicationBanner } from '@widgets/cabinet/customer/CabinetCustomerCanCreateApplicationBanner';

const SantaClausLetterPage = () => {
  const navigate = useNavigate();

  if (!config.features.santaClausLetter) {
    navigate(Routes.NotFoundPage);

    return null;
  }

  return (
    <div>
      <CabinetCustomerCanCreateApplicationBanner />

      <div className="px-4 pt-6 pb-12 lg:pt-12">
        <SantaClausTitle />

        <SantaClausLetterForm />
      </div>
    </div>
  );
};

export default SantaClausLetterPage;
