import React from 'react';

import { Element } from '@shared/interfaces/element.interface';
import { AttachmentIcon } from '@shared/icons/attachment';

export interface FileUploadFieldProps extends Element {
  id?: string;

  name?: string;

  label?: string;

  disabled?: boolean;

  otherProps?: object;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = (props) => {
  const { id, name = id, label, className, disabled, otherProps } = props;

  return (
    <div className={className}>
      <label htmlFor={id} className="flex items-center gap-2 transition-colors hover:text-rich-blue cursor-pointer">
        <AttachmentIcon />
        {label}
      </label>

      <input {...otherProps} id={id} name={name} type="file" disabled={disabled} />
    </div>
  );
};
