import React from 'react';
import { IconProps } from '@shared/interfaces/icon.interface';

export const AttachmentIcon: React.FC<IconProps> = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.75 7.18l-6.382 6.354a4.179 4.179 0 01-5.897 0 4.141 4.141 0 010-5.87L8.854 1.31a2.786 2.786 0 013.93 0 2.761 2.761 0 010 3.914l-6.389 6.354a1.393 1.393 0 01-1.965 0 1.38 1.38 0 010-1.957l5.896-5.863"
      ></path>
    </svg>
  );
};
