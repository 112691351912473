import { emptySplitApi } from '../emptySplitApi';

interface CreateLetterRequestDto {
  name: string;

  age: number;

  text: string;

  picture: string | null;
}

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    createLetter: build.mutation<{ success: boolean }, FormData>({
      query: (dto) => ({
        url: '/santa-claus/letters',
        method: 'POST',
        body: dto,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateLetterMutation } = extendedApi;
