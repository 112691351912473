import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileUploadField, FileUploadFieldProps } from './FileUploadField';

type HookFormFileUploadFieldProps = Omit<FileUploadFieldProps, 'otherProps'> & { id: string };

export const HookFormFileUploadField: React.FC<HookFormFileUploadFieldProps> = (props) => {
  const { id } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const errorText = errors[id]?.message;

  return (
    <div>
      <FileUploadField {...props} otherProps={register(id)} />

      {errorText && <span className="mt-[5px] px-4 text-xxs text-carnation">{t(errorText, { field: 'picture' })}</span>}
    </div>
  );
};
